@use '../../../abstracts' as *;

.book {
  @include button-reset();
  border-bottom: none;
  text-align: center;

  &,
  &__link {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .book__link {
    border-bottom: none;
  }

  &:not(.book--inactive):not(.book--list-item):hover .book__cover {
    box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);
  }

  &--inactive {
    img,
    .book__details,
    .book__link:not(.pill) {
      filter: grayscale(100%);
    }

    .book__details {
      color: var(--body-color);
    }
  }

  &__content {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 1rem;
  }

  &__cover {
    position: relative;
    width: 6.875rem;
    height: 9.375rem;
    background: currentColor;
    transition: box-shadow 0.15s ease;
    border: 0.3125rem solid var(--white);
    box-shadow: 0 0 0.75rem 0 var(--grey-500);

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--unavailable {
      background-color: var(--white);
    }
  }

  &__details {
    @include caption-1();
    max-width: 9.375rem;
    font-weight: 700;
    color: var(--blue-900);
    display: flex;
    flex-direction: column;

    small {
      @include caption-2();
      font-weight: normal;
    }
  }

  &__link--activate {
    max-width: 9.375rem;
    text-align: center;

    .pill {
      margin-top: 0.5rem;
      display: inline-block;
    }

    &:hover {
      color: var(--white);
    }
  }

  &--list-item {
    font-size: 0.75rem;
    color: var(--link-color);
    text-align: left;

    .book__cover {
      box-shadow: none;
    }

    figure {
      padding: 0.75rem;
      gap: 1rem;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
    }

    .book__link--absolute-coverage {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }

    & + & {
      border-top: 0.0625rem solid var(--blue-200);
    }

    .book__link.active {
      .book__name {
        color: var(--primary-400);
      }
    }

    &:hover {
      background-color: var(--grey-100);
    }

    .book__content {
      gap: 0.5rem;
      align-self: flex-start;
    }

    .book__details {
      align-items: flex-start;
      span {
        order: 2;

        &.pill {
          margin-top: 0.5rem;
        }
      }
      small {
        order: 1;
      }
    }

    .book__cover {
      border: none;
      height: 2.5rem;
      width: 1.875rem;
      min-width: 1.875rem;
      object-fit: cover;
      object-position: center;
      transition: box-shadow 0.15s ease;

      &--unavailable {
        border: 0.0625rem solid var(--grey-400);
      }
    }

    .pill {
      align-self: flex-start;
    }

    .pill--activate {
      position: relative;
      z-index: 1;
    }
  }

  &--app {
    align-items: center;

    .book__cover {
      img {
        + .icon-book {
          opacity: 0;
        }
      }

      .icon-book {
        transition: opacity 0.25s ease;
        fill: var(--white);
        height: 3rem;
        width: 3rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      &--overlay {
        &::after {
          transition: opacity 0.25s ease;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: currentColor;
          opacity: 0;
        }
      }

      &--transparent {
        background: transparent;
      }
    }

    .book__details {
      gap: 0.25rem;
    }

    &:enabled:hover {
      .book__cover {
        .icon-book {
          opacity: 1;
        }

        &--overlay {
          &::after {
            opacity: 0.85;
          }
        }
      }
    }
  }
}

.book-selector {
  &--switcher {
    .book-selector__header {
      background-color: var(--white);
      border-color: var(--body-color);
      position: relative;
      z-index: 3;
    }

    .book-selector__list {
      position: absolute;
      width: 100%;
      background-color: var(--white);
      border-bottom: 0.125rem solid var(--module-color);
      z-index: 3;

      &--visible {
        display: block;
      }
    }

    &.book-selector--overlay {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
      }
    }
  }

  &--grid {
    @include mq(xlarge) {
      --repeat: 4;
    }

    @media screen and (min-width: 75rem) and (max-width: 85rem) {
      --repeat: 3;
    }

    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(var(--repeat, auto-fit), minmax(10rem, 1fr));
    gap: 1.5rem 1rem;
  }

  .book-selector__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-bottom: 0.0625rem solid var(--module-color);
    color: var(--blue-900);
  }

  .book-selector__header-content {
    display: flex;
    flex-direction: column;
  }

  .book-selector__name {
    font-size: 0.9rem;
  }

  .book-selector__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.book-selector__list {
  @include no-scrollbar;
  max-height: calc(100vh - 4.0625rem - 6rem);
  overflow-y: auto;
}

.book-switcher {
  &__button {
    @include button-reset();
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--module-color);
    flex-shrink: 0;

    svg {
      width: 0.625rem;
      height: 0.625rem;
      fill: var(--white);
    }
  }
}

.body--bao {
  .book__details {
    color: var(--blue-400);
  }

  .book-selector__header-content {
    color: var(--blue-600);
  }
}
