@use '../abstracts' as *;
@use '../base/helpers' as *;

@mixin book() {
  .book {
    width: 8rem;
    margin: 0;
    gap: 1.5rem;
    flex-shrink: 0;

    &__cover {
      object-fit: cover;
      width: 100%;
      height: 12rem;
    }

    &__title {
      color: var(--blue-900);
      transition: all 0.25s ease;
    }
  }
}

.my-groups {
  .school-year-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @include mq(medium) {
      flex-wrap: nowrap;

      .bc-select {
        max-width: 24rem;
      }
    }

    [type='button'] {
      @include mq(medium, 'down') {
        width: 100%;
      }
    }
  }

  .group-name {
    width: min(25ch, 100%);
    display: flex;
  }

  .group-summary {
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    border-right: 0.0625rem solid var(--blue-300);
    display: flex;
    align-items: center;
    text-align: right;
    @include caption-1;
  }

  .smartschool-logo {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.25rem;
    margin-left: 1rem;
  }

  .tabpanel {
    margin-top: 0.5rem;
  }

  .group-information {
    .bc-editable-text-field__text-field {
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      border: 0.0625rem solid var(--blue-400);
      border-radius: 0.125rem;

      &:focus {
        border-bottom: 0.0625rem solid var(--info-400);
        box-shadow: 0 0.0625rem 0 0 var(--info-400), 0 0 0.5rem var(--grey-400);
        outline: none;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      }
    }
    .bc-editable-text-field__label {
      @include heading-5;
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
      margin-top: 0;
    }
  }

  .bc-button__button {
    margin-left: 0;
  }

  .group-actions {
    .group-actions__button + .group-actions__button {
      margin-left: 0;
    }

    &__button {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
    }
  }

  .pill {
    justify-content: center;
    flex-shrink: 0;
  }

  .collapsible__title {
    justify-content: space-between;
    gap: 1rem;
  }

  .student-modules-panel {
    &__modules {
      margin-top: 2.5rem;

      & > div {
        margin-bottom: 2rem;
      }

      .book-button__wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        > button {
          @include button-reset();
        }

        &:hover {
          .book__cover {
            box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);
          }
        }
      }

      .remove-student-module {
        @include button-reset;
        margin-bottom: 3rem;
        .book__cover {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            filter: brightness(0.6);
          }
        }

        .icon-minus {
          position: absolute;
          z-index: 1;
          color: var(--white);
          width: 3rem;
          height: 3rem;
        }
      }

      .icon {
        color: white;
      }
    }
  }

  .students-panel {
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      & > h4 {
        color: var(--primary-400);
        margin: 0;
      }

      .actions-wrapper {
        display: flex;
        justify-content: flex-end;

        @media only screen and (max-width: 992px) {
          display: block;
        }

        & > button {
          display: inline;

          // px value copied from _grids.scss. breakpoints contains different values.
          @media only screen and (max-width: 992px) {
            display: block;
            margin-left: 0;
            margin-bottom: 1rem;
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__content {
      margin-top: 2.5rem;

      .member-actions {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  @include book;
}

.nested-panel {
  &__heading {
    margin-bottom: 0;
    display: flex;
    gap: 1rem;
  }
}

.student-panel {
  .student-panel__heading {
    margin-bottom: 0;

    .pill {
      position: relative;
      margin-left: 0.5rem;
    }
  }

  .student-panel__subtitle {
    @include uppercase-bold;
    margin: 1.5rem 0;

    &--accepted {
      color: var(--success-400);
    }

    &--pending {
      color: var(--warning-400);
    }

    &--declined {
      color: var(--danger-400);
    }
  }

  .students-panel__smartschool-instruction {
    margin-top: 2rem;
  }

  .student-panel__category-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .student-panel__table {
    width: 100%;
    overflow: hidden;

    tr {
      border-top: 0.0625rem solid var(--blue-200);
      transition: background-color 0.2s ease;

      &:last-child {
        border-bottom: 0.0625rem solid var(--blue-200);
      }

      &:hover {
        background-color: var(--grey-100);
      }
    }

    td {
      &.students-table__action {
        width: 2.5rem;
        vertical-align: middle;
        text-align: center;
      }
      padding: 0.75rem;
    }

    .students-table__no-results {
      text-align: left;
      @include caption-1;
    }
  }

  .invite-student {
    margin-top: $spacer-700;
    display: flex;

    &__warning {
      color: var(--warning-400);
      margin-top: $spacer-100;
    }

    .bc-formfield__field {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: $spacer-400;

      small {
        margin-top: $spacer-100;
      }

      &-contains-error {
        .bc-formfield__textfield {
          order: 1;
        }
        .bc-formfield__danger {
          order: 2;
        }
        .invite-student__info {
          order: 3;
        }
      }
    }
  }
}

#activation-link-modal {
  .static-field {
    canvas {
      display: block;
      margin: 0 auto;
    }
  }

  .download-qr-code {
    display: inline-flex;
  }

  .activation-link-modal__subtitle {
    margin: 1.5rem 0 0.5rem;
  }
}

#add-group-modal,
#add-smartschool-group-modal {
  .bc-checkbox {
    display: inline-flex;
  }

  .bc-label__label {
    display: flex;

    &[for='all-groups'] {
      margin-bottom: 0.5rem;
    }

    + p {
      margin-top: 1rem;
    }
  }

  .group-checkboxes {
    display: flex;
    margin-left: 1.5rem;
    flex-wrap: wrap;

    .bc-label__label {
      flex: 50%;
    }
  }
}

#add-group-modal {
  #add-group :last-child {
    margin-bottom: 0;
  }

  .group-name-label {
    display: block;
    margin-bottom: 1rem;

    span {
      display: flex;
      margin-bottom: 1rem;
    }
  }
}

#group-material-modal {
  .book-selector-checkbox {
    padding: 0.5rem 0;

    .bc-checkbox {
      left: initial;
      margin: 0;
    }
  }

  .group-module-form {
    height: 100%;

    &__modules-wrapper {
      height: 100%;

      > div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        > span {
          flex-grow: 0;
        }
      }
    }

    .border {
      &--right {
        padding-right: 2rem;
        border-right: 0.0625rem solid var(--grey-300);
      }

      &--left {
        padding-left: 2rem;
        border-left: 0.0625rem solid var(--grey-300);
      }
    }

    &__modules {
      padding: 1rem 0;
      max-height: calc(
        100vh - 2 * 85px - 2 * 1.5rem - 2 * 2rem - 2 * 1.5rem - 2 * 1rem - 1.5rem
      ); // height vp - (header + footer) - (margin b + t) - 2 * padding dialog body - line height - 2 * padding form - spacing heading
      overflow-y: auto;
      align-self: normal;
    }
  }

  @include book;
}

#group-schoolyear-modal {
  .warning {
    @include caption-1;
    display: block;
    color: var(--warning-400);
    padding-top: 1rem;
  }
}

.table-students-vo td {
  word-break: break-word;
}
